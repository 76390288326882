import React, { useState } from "react"
import "./BigBanner.scss"

import MainPageBackground from "../../images/main-page-bg.jpg"
import PlannerBackground from "../../images/planner-bg.png"
import SheltersBackground from "../../images/banner2-bg.jpg"

const BigBanner = ({
  title,
  text,
  buttonText,
  buttonLink,
  textColourScheme,
  pageName
}) => {
  const [darkText, setDark] = useState(textColourScheme === "dark")
  const isDark = darkText ? "dark" : ""

  function resolvePicture() {
    switch (pageName) {
      case 'main':
        return MainPageBackground
      case 'planner':
        return PlannerBackground
      default:
        return SheltersBackground
    }
  }
  return (
    <div className="">
      <a
        href={buttonLink}
        className="big-banner d-flex align-items-center"
        style={{
          background: `url(${resolvePicture()}) no-repeat center center`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="col big-banner__content-wrapper ps-7">
          <div className="title-description-wrapper">
            <div className={`big-banner-title ${isDark}`} dangerouslySetInnerHTML={{ __html: title }}></div>
            <div className={`description ${isDark}`}>{text}</div>
          </div>
          <div className="btn btn-primary btn-lg mt-5 text-white">
            {buttonText}
          </div>
        </div>
      </a>
    </div>
  )
}

export default BigBanner
