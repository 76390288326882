import React, { useState } from "react"
import "./ProjectsList.scss"

import LearnMoreBtn from "../learn-more-btn/LearnMoreBtn"
import { Modal } from "react-bootstrap"
import { getImageUrl } from "../../services/Utils"

const ProjectsList = ({ projects, itemsInRow, learnMoreBtnText, video }) => {
  const [show, setShow] = useState(false)
  const [videoLink, setVideoLink] = useState()
  const handleClose = () => setShow(false)
  const handleShow = link => {
    setVideoLink(link)
    setShow(true)
  }

  const configureItemsInTheRowAmount = itemsInRow => {
    if (itemsInRow === 2) {
      return "col-lg-6"
    } else if (itemsInRow === 4) {
      return "col-md-6 col-lg-3"
    }
  }

  return (
    <div className="container">
      <div className="projects-list row flex-wrap">
        {projects && projects.length
          ? projects.map(item => {
            return (
              <div
                key={item.id}
                className={`projects-list__item d-flex flex-column justify-content-between ${configureItemsInTheRowAmount(
                  itemsInRow
                )} mt-3`}
              >
                {video ? (
                  <div
                    onClick={() => {
                      handleShow(item?.videoLink)
                    }}
                  >
                    <img
                      src={item?.importedPicture ? item?.imageUrl : getImageUrl(item?.imageUrl)}
                      alt="No image"
                      className="rounded-img"
                    />
                    <div className="title-description-wrapper">
                      <div className="title mb-3">{item?.title}</div>
                      <div
                        className="description mb-3"
                        dangerouslySetInnerHTML={{
                          __html: item?.description,
                        }}
                      />
                    </div>
                    <div>
                      <LearnMoreBtn props={item} />
                    </div>
                  </div>
                ) : (
                  <>
                    {item.detailsUrl ? (
                      <>
                        <a href={item.detailsUrl}>
                          <div className="content mb-3">
                            <div className="content-overlay"></div>
                            <img
                              src={item?.importedPicture ? item?.imageUrl : getImageUrl(item?.imageUrl)}
                              alt="No image"
                              className="rounded-img content-image"
                            />
                          </div>
                          <div className="title-description-wrapper">
                            <div className="title mb-3">{item?.title}</div>
                            <div
                              className="description mb-3"
                              dangerouslySetInnerHTML={{
                                __html: item?.description,
                              }}
                            />
                          </div>

                          {/* <LearnMoreBtn props={item} /> */}
                        </a>
                        <LearnMoreBtn props={item} />
                      </>
                    ) : (
                      <>
                        <img
                          src={item?.importedPicture ? item?.imageUrl : getImageUrl(item?.imageUrl)}
                          alt="No image"
                          className="rounded-img"
                        />
                        <div className="title-description-wrapper">
                          <div className="title mb-3">{item?.title}</div>
                          <div
                            className="description mb-3"
                            dangerouslySetInnerHTML={{
                              __html: item?.description,
                            }}
                          />
                        </div>
                        <div>
                          <LearnMoreBtn props={item} />
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            )
          })
          : null}
        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          contentClassName="video-modal"
        >
          <Modal.Body>
            <iframe
              type="text/html"
              key={1}
              title="YouTube video player"
              frameBorder="0"
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              src={videoLink}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default ProjectsList
