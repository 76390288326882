import * as React from "react"
import Seo from "../components/seo/seo"
import BigBanner from "../shared/big-banner/BigBanner"
import ProjectsList from "../shared/projects-list/ProjectsList"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
// import mockupsPro from "../images/mockupsProPagePlanner.jpg"
import mockupsPro from "../images/mockupsProPagePlanner2.jpg"
import bma from "../images/bma.jpg"

const PlannerPage = props => {
  const staticContent = props.pageContext.staticContent
  const projects = props.pageContext.resultProjects
  const menu = props.pageContext.menu
  const staticHeaderPage = props.pageContext.staticHeaderPage


  const plannerStaticContent = props.pageContext.plannerStaticContent

  const plannerList = [
    {
      description: plannerStaticContent["Assistant Text"],
      detailsTitle: plannerStaticContent["Assistant Details Button Text"],
      detailsUrl: plannerStaticContent["Assistant Details Button Link"],
      engTitle: "assistant",
      id: 0,
      imageUrl: bma,
      position: 0,
      resolvedImageUrl: bma,
      title: plannerStaticContent["Assistant Title"],
      importedPicture: true
    },
    projects.find(el => el.detailsUrl.includes("easyboard")),
    projects.find(el => el.detailsUrl.includes("mockups")),
    {
      description: plannerStaticContent["Mockups Pro Text"],
      detailsTitle: plannerStaticContent["Mockups Pro Details Button Text"],
      detailsUrl: plannerStaticContent["Mockups Pro Details Button Link"],
      engTitle: "mockupsPro",
      id: 4,
      imageUrl: mockupsPro,
      position: 4,
      resolvedImageUrl: mockupsPro,
      title: plannerStaticContent["Mockups Pro Title"],
      importedPicture: true
    }
  ]

  return (
    <div className="index-wrapper">
      <Header
        menu={menu}
        siteTitle={staticContent["<title>"]}
        contents={staticHeaderPage}
        displayLogoLinks={true}
      />
      <Seo
        keywordsContent={staticContent["<meta> keywords"]}
        descriptionContent={staticContent["<meta> description"]}
        title={staticContent["<title>"]}
      />
      <div className="container">
        <BigBanner
          title={plannerStaticContent["Planner Page Title"]}
          buttonText={plannerStaticContent["Planner Page Button Text"]}
          buttonLink={plannerStaticContent["Planner Page Big Banner Link"]}
          textColourScheme="dark"
          pageName="planner"
        />
      </div>
      <ProjectsList
        projects={plannerList}
        itemsInRow={4}
        learnMoreBtnText={plannerList?.detailsTitle}
        video={false}
      />
      <div className="mb-6" />

      <Footer
        recaptchaSettings={props.pageContext.recaptchaSettings}
        staticContactsForm={props.pageContext.staticContactsForm}
        staticContactsPage={props.pageContext.staticContactsPage}
      />
    </div>
  )
}

export default PlannerPage
