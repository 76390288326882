import * as React from "react"
import "./LearnMoreBtn.scss"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
const LearnMoreBtn = props => {
  const param = props.props
  const [buttonContent, setButtonContent] = React.useState([])

  React.useEffect(() => {
    if (param) {
      setButtonContent(param)
    }
  }, [])
  return (
    <>
      {props.buttonLabel && (
        <div className="learn-more-btn-wrapper">
          <div className="learn-more-btn d-flex gap-2 align-items-center curs-P">
            <div className="learn-more-text">{props.buttonLabel}</div>
            <StaticImage
              src="../../icons/arrow-right.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Project picture"
              className="blue-arrow"
            />
          </div>
        </div>
      )}
      {buttonContent &&
        buttonContent.detailsUrl &&
        buttonContent.detailsTitle ? (
        <div className="learn-more-btn-wrapper">
          <a href={buttonContent?.detailsUrl}>
            <div className="learn-more-btn d-flex gap-2 align-items-center curs-P">
              <div className="learn-more-text">
                {buttonContent?.detailsTitle}
              </div>
              <StaticImage
                src="../../icons/arrow-right.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Project picture"
                className="blue-arrow"
              />
            </div>
          </a>
        </div>
      ) : buttonContent.orderText && buttonContent.orderUrl ? (
        <div className="learn-more-btn-wrapper">
          <a href={buttonContent?.orderUrl}>
            <div className="learn-more-btn d-flex gap-2 align-items-center curs-P">
              <div className="learn-more-text">{buttonContent?.orderText}</div>
              <StaticImage
                src="../../icons/arrow-right.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Project picture"
                className="blue-arrow"
              />
            </div>
          </a>
        </div>
      ) : (
        props.buttonId === "details" && (
          <div className="learn-more-btn-wrapper">
            <Link to={`${props.urlName ? "/gallery/" + props.urlName : "/gallery"}`}>
              <div className="learn-more-btn d-flex gap-2 align-items-center curs-P">
                <div className="learn-more-text">{props.buttonText}</div>
                <StaticImage
                  src="../../icons/arrow-right.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Project picture"
                  className="blue-arrow"
                />
              </div>
            </Link>
          </div>
        )
      )}
    </>
  )
}

export default LearnMoreBtn
